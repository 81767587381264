import { useState } from "react";
import { createContext } from "react";
import { Button } from "@vwfs-bronson/bronson-react";
import { useEffect } from "react";
import {
    setSelectedMantenimentoStandAlone,
    setSelectedResumeOffers,
    setSelectedSeguroAutoStandAlone,
} from "../actions/summaryAction";
import { addNotification } from "../actions/notificationAction";
import {
    createQuoteOffer,
    saveMantenimientoStandAloneOffer,
    saveOffersFSOVLongDriveRelation,
    saveSeguroAutomovilStandAloneOffer,
    setIsMantenimientoOfferSended,
    setIsSeguroAutomovilOfferSended,
    setIsSendingOffers,
    setIsSimulatorOfferSended,
} from "../actions/offerAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSummary } from "../hooks/useSummary";
import { useGlobals } from "../hooks/useGlobals";
import Notification, { SEVERITY_NOTIFICACION, TYPE_NOTIFICACION } from "../class/Notification";
import { useWizard } from "../hooks/useWizard";
import { CalculatorTypeEnum, StepsNameEnum } from "../types/enums";
import { useOffer } from "../hooks/useOffer";
import { getRequestWithHeadersSalesAssist } from "../config/axiosClient";
import { hasOffersPriceChange } from "../helpers/hasOffersPriceChange";
import { useClient } from "../hooks/useClient";
import { useForm } from "../hooks/useForm";

export const WizardConext = createContext();

export const WizardProvider = ({ children }) => {
    const dispatch = useDispatch();
    const { client } = useClient();
    const [alreadyRedirect, setAlreadyRedirect] = useState(false);
    const navigate = useNavigate();
    const { calculatorType, returnSalesassistInfo, isSalesAssist } = useGlobals();
    const { offers, isSimulatorOfferSended, isMantenimientoOfferSended, isSeguroAutomovilOfferSended } = useOffer();
    const {
        selectedResumeOffers,
        selectedMantenimentoStandAlone,
        selectedSeguroAutoStandAlone,
        selectedClientStandAlone,
    } = useSummary();
    const { steps, backStep, nextStep, stepSelected, isDirty, validateDownloadCheckbox } = useWizard();
    const { maxOffersToSend } = useGlobals();

    const [backButton, setBackButton] = useState(null);
    const [nextButton, setNextButton] = useState(null);
    const [leaveButton, setLeaveButton] = useState(null);

    const [download, setdownload] = useState(false);
    const [isSeguroDocsMounted, setIsSeguroDocsMounted] = useState(false);

    const [outOfWindow, setOutOfWindow] = useState(true);
    const [doesntHaveMaintenance, setDoesntHaveMaintenance] = useState(false);
    const [lastMaintenance, setLastMaintenance] = useState("");
    const [formOutOfWindow, setHandleOnChangeOutOfWindow, , setValuesOutOfWindow] = useForm({
        OutOfWindow: false,
        KmLastMaintenance: undefined,
        LastMaintenance: undefined,
    });

    const OutOfWindowAction = {
        formOutOfWindow,
        setHandleOnChangeOutOfWindow,
        setValuesOutOfWindow,
        outOfWindow,
        setOutOfWindow,
        doesntHaveMaintenance,
        setDoesntHaveMaintenance,
        lastMaintenance,
        setLastMaintenance,
    };

    const initFormData = {
        ClaveRol: "",
        NIF: "",
        TipoDocumento: "",
        PersonaFisicaJuridica: client?.Class?.Key ?? "F",
        FechaNacimiento: "",
        FechaCarne: "",
        CodigoPostal: "",
        IdProvince: "",
        IdPoblacion: "",
        Provincia: "",
        Poblacion: "",
        FechaEfecto: "",
        Nacionality: "",
    };

    const [conductorHabitual, setConductorHabitual] = useState(true);
    const [conductorOcasional, setConductorOcasional] = useState(false);
    const [Cobertura, setCobertura] = useState(null);
    const [formValuesTomador, setHandleOnChangeTomador, , setValuesTomador] = useForm({
        ...initFormData,
        ClaveRol: "TOM",
    });
    const [formValuesConductorHabitual, setHandleOnChangeConductorHabitual, , setValuesConductorHabitual] = useForm({
        ...initFormData,
        ClaveRol: "COH",
    });
    const [formValuesConductorOcasional, setHandleOnChangeConductorOcasional, , setValuesCondutorOcasional] = useForm({
        ...initFormData,
        ClaveRol: "CO2",
    });
    const SeguroAutoActions = {
        conductorHabitual,
        setConductorHabitual,
        conductorOcasional,
        setConductorOcasional,
        Cobertura,
        setCobertura,
        formValuesTomador,
        setHandleOnChangeTomador,
        setValuesTomador,
        formValuesConductorHabitual,
        setHandleOnChangeConductorHabitual,
        setValuesConductorHabitual,
        formValuesConductorOcasional,
        setHandleOnChangeConductorOcasional,
        setValuesCondutorOcasional,
    };

    useEffect(() => {
        if (stepSelected) {
            if (
                stepSelected?.index === 0 &&
                (calculatorType === CalculatorTypeEnum.MANTENIMIENTOSTANDALONE ||
                    calculatorType === CalculatorTypeEnum.SEGUROAUTOSTANDALONE ||
                    calculatorType === CalculatorTypeEnum.ALLSTANDALONE)
            ) {
                setBackButton(null);
                return;
            }
            setBackButton(
                <Button onClick={handleBackButton} link small icon="semantic-back">
                    Volver
                </Button>,
            );
            return;
        }
        setBackButton(null);
        return;
    }, [stepSelected, selectedMantenimentoStandAlone, selectedSeguroAutoStandAlone, selectedClientStandAlone]);

    useEffect(() => {
        if (
            stepSelected &&
            stepSelected.name !== StepsNameEnum.DATOSCLIENTE &&
            stepSelected.name !== StepsNameEnum.RESUMEN
        ) {
            setLeaveButton(
                <Button onClick={dontWantProduct} link>
                    {"No quiero " + stepSelected.name}
                </Button>,
            );
            return;
        }
        setLeaveButton(null);
        return;
    }, [stepSelected, selectedMantenimentoStandAlone, selectedSeguroAutoStandAlone, selectedClientStandAlone]);

    useEffect(() => {
        if (!stepSelected) return;

        if (stepSelected.name === StepsNameEnum.RESUMEN) {
            // const offersSeguros =
            //     offers?.simulations?.filter(
            //         (x) =>
            //             x.Simulations?.filter(
            //                 (c) =>
            //                     c.AdditionalProducts.Servicios.filter(
            //                         (v) =>
            //                             v.CodigoServicio === "SE" ||
            //                             v.CodigoServicio === "SR" ||
            //                             v.CodigoServicio === "SA"
            //                     ).length > 0
            //             ).length > 0
            //     ).length > 0;
            //const disabled =
            // ((selectedSeguroAutoStandAlone?.SeguroContratado !== null || offersSeguros) && download) ||
            // (selectedSeguroAutoStandAlone?.SeguroContratado === null && !offersSeguros && !download);
            setNextButton(<Button onClick={sendOffers}> Aceptar </Button>);
            return;
        }
        if (
            (stepSelected.name === StepsNameEnum.MANTENIMIENTO && selectedMantenimentoStandAlone !== null) ||
            (stepSelected.name === StepsNameEnum.SEGUROAUTOMOVIL &&
                selectedSeguroAutoStandAlone?.SeguroContratado !== null) ||
            stepSelected.name === StepsNameEnum.DATOSCLIENTE
        ) {
            setNextButton(<Button onClick={() => nextStep()}> Siguiente </Button>);
            return;
        }

        setNextButton(null);
    }, [
        stepSelected,
        selectedMantenimentoStandAlone,
        selectedSeguroAutoStandAlone,
        selectedClientStandAlone,
        download,
        isSeguroDocsMounted,
    ]);

    useEffect(() => {
        if (isSimulatorOfferSended && isMantenimientoOfferSended && isSeguroAutomovilOfferSended) {
            let errorCreateOffers =
                (isSimulatorOfferSended.NeedSend && !isSimulatorOfferSended?.ItsDone) ||
                (isMantenimientoOfferSended.NeedSend && !isMantenimientoOfferSended?.ItsDone) ||
                (isSeguroAutomovilOfferSended.NeedSend && !isSeguroAutomovilOfferSended?.ItsDone);

            if (errorCreateOffers) {
                dispatch(setIsSendingOffers(false));
                var notificationerror = new Notification.fromObject({
                    Description: "Ha ocurrido un error al generar las ofertas",
                    maxOffersToSend,
                    Timeout: 5000,
                    Type: TYPE_NOTIFICACION.TOAST,
                    Severity: SEVERITY_NOTIFICACION.ERROR,
                });
                dispatch(addNotification(notificationerror));
                return;
            }

            if (offers.length > 0) {
                if (hasOffersPriceChange(offers)) {
                    //TODO: Mirar que hacer en este caso visto que ahora tenenmos el wizard
                    navigate("/simulator/offerspricechange");
                    dispatch(setIsSendingOffers(false));
                    return;
                }
                if (calculatorType === CalculatorTypeEnum.FINANCE) dispatch(saveOffersFSOVLongDriveRelation());
                redirect();
                return;
            }
            if (calculatorType != CalculatorTypeEnum.FINANCE && calculatorType != CalculatorTypeEnum.RENTING) {
                redirect();
                return;
            }
        }
    }, [offers, isSimulatorOfferSended, isMantenimientoOfferSended, isSeguroAutomovilOfferSended, navigate]);

    const redirect = () => {
        if (returnSalesassistInfo && !alreadyRedirect) {
            if (isSalesAssist) {
                //Is from Sales Assist
                const headers = {
                    client_id: returnSalesassistInfo.client_id,
                    client_secret: returnSalesassistInfo.client_secret,
                };
                setAlreadyRedirect(true);
                getRequestWithHeadersSalesAssist(returnSalesassistInfo.returnUrl, headers);
                return;
            } else {
                // Is from IMAWEB
                document.location.href = returnSalesassistInfo.returnUrl;
            }
        }
    };

    const dontWantProduct = () => {
        if (stepSelected.name === StepsNameEnum.MANTENIMIENTO) {
            dispatch(setSelectedMantenimentoStandAlone(null));
        } else if (stepSelected.name === StepsNameEnum.SEGUROAUTOMOVIL) {
            dispatch(setSelectedSeguroAutoStandAlone(null));
        }

        nextStep();
    };

    const sendOffers = () => {
        if (isSeguroDocsMounted && !validateDownloadCheckbox(download)) {
            return;
        }

        if (selectedResumeOffers.length > maxOffersToSend) {
            var notification = new Notification.fromObject({
                Description: "Número máximo de ofertas es de " + maxOffersToSend,
                maxOffersToSend,
                Timeout: 5000,
                Type: TYPE_NOTIFICACION.TOAST,
                Severity: SEVERITY_NOTIFICACION.ERROR,
            });
            dispatch(addNotification(notification));
            return;
        }
        dispatch(setIsSendingOffers(true));
        if (selectedResumeOffers && selectedResumeOffers.length > 0)
            dispatch(createQuoteOffer({ Offers: selectedResumeOffers }));
        else {
            var retvaloffers = {
                IsSended: true,
                NeedSend: false,
                ItsDone: false,
                OfferId: "",
            };
            dispatch(setIsSimulatorOfferSended(retvaloffers));
        }
        if (selectedMantenimentoStandAlone && selectedClientStandAlone) dispatch(saveMantenimientoStandAloneOffer());
        else if (!selectedMantenimentoStandAlone) {
            var retval = {
                IsSended: true,
                NeedSend: false,
                ItsDone: false,
                OfferId: "",
                NumOfertaCliente: "",
            };
            dispatch(setIsMantenimientoOfferSended(retval));
        }
        if (selectedSeguroAutoStandAlone && selectedClientStandAlone) dispatch(saveSeguroAutomovilStandAloneOffer());
        else if (!selectedSeguroAutoStandAlone) {
            var retvalSeg = {
                IsSended: true,
                NeedSend: false,
                ItsDone: false,
                OfferId: "",
                NumOfertaCliente: "",
            };
            dispatch(setIsSeguroAutomovilOfferSended(retvalSeg));
        }
    };

    const handleBackButton = () => {
        if (stepSelected) {
            if (stepSelected.index === 0) {
                dispatch(setSelectedResumeOffers([]));
                navigate("/simulator");
            } else {
                backStep();
            }
        }
    };

    return (
        <WizardConext.Provider
            value={{
                steps,
                isDirty,
                stepSelected,
                backButton,
                leaveButton,
                nextButton,
                SeguroAutoActions,
                OutOfWindowAction,
                download,
                setdownload,
                isSeguroDocsMounted,
                setIsSeguroDocsMounted,
            }}
        >
            {children}
        </WizardConext.Provider>
    );
};
